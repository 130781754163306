<!-- 领奖用户列表 -->
<template>
  <div class="vhc-container">
    <!-- 标题栏 -->
    <pageTitle pageTitle="领奖用户"></pageTitle>

    <!-- 顶部搜索栏 -->
    <div class="vhc-search-bar">
      <vh-button size="medium" type="primary" round @click="handleExport">导出明细</vh-button>
      <vh-input
        round
        class="search-input"
        placeholder="请输入用户昵称"
        v-model="keyword"
        clearable
        v-clearEmoij
        @clear="handleSearch"
        @keyup.enter.native="handleSearch"
      >
        <i class="vh-icon-search vh-input__icon" slot="prefix" @click="handleSearch"></i>
      </vh-input>
    </div>

    <!-- 内容区 -->
    <div class="vhc-container__bd">
      <div class="vhc-main">
        <!-- 数据列表 -->
        <div class="vhc-main-content">
          <vh-table ref="tableList" :data="dataList" v-loading="loading" class="vhc-main-table">
            <vh-table-column prop="winner_nickname" label="用户名称" width="160" />
            <vh-table-column prop="winner_phone" label="手机号" width="120" />
            <vh-table-column prop="award_info" label="礼品信息">
              <template slot-scope="scope">
                <div class="media-box">
                  <div class="media-box__hd">
                    <vh-image fit="cover" :src="scope.row.award_info?.award_img_url"></vh-image>
                  </div>
                  <div class="media-box__bd">
                    <span>{{ scope.row.award_info?.award_name }}</span>
                  </div>
                </div>
              </template>
            </vh-table-column>
            <vh-table-column prop="push_time" label="推送时间" width="160">
              <template slot-scope="scope">
                {{ scope.row.push_time }}
              </template>
            </vh-table-column>
            <vh-table-column prop="winning_time" label="中奖时间" width="160"></vh-table-column>
          </vh-table>
          <SPagination
            :total="total || 0"
            :page-size="pagination.limit"
            :currentPage="pagination.pageNo"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></SPagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import PageTitle from '@/components/PageTitle';
  import noData from '@/views/PlatformModule/Error/nullPage';
  import to from 'await-to-js';

  export default {
    name: 'InteractiveRewardUserList',
    components: {
      PageTitle,
      noData
    },
    data() {
      return {
        webinarId: '', //活动id
        interactRewardId: '', //互动有礼id
        isInited: false, // 是否有成功的请求
        loading: false,
        isEmpty: true, // 是否完全空数据
        total: 0,
        keyword: '',
        dataList: [],
        // 分页参数
        pagination: {
          pos: 0,
          limit: 20,
          pageNo: 1
        }
      };
    },
    methods: {
      // 导出明细
      async handleExport() {
        const params = {
          webinar_id: this.webinarId,
          interact_reward_id: this.interactRewardId,
          winner_nickname: this.keyword // 用户昵称
        };
        const [err, res] = await to(this.$fetch('exportInteractRewardUsers', params));
        if (err || !res || res.code != 200) {
          this.$message.error(err.msg || res?.msg || '请求失败');
          return;
        }
        this.$vhMessage.success(
          `导出申请成功，${
            this.$store.state.permissionValue.custom_download_toast || '请去下载中心下载'
          }`
        );
        this.$EventBus.$emit('saas_vs_download_change');
      },
      // 搜索
      handleSearch() {
        this.pagination.pos = 0;
        this.fetchData();
      },
      // 翻页事件
      handleCurrentChange(current) {
        this.pagination.pageNo = current; //当前页码
        this.pagination.pos = parseInt((current - 1) * this.pagination.limit);
        this.fetchData();
      },
      // 每页条数改变时触发
      handleSizeChange(limit) {
        this.pagination.limit = limit;
        this.pagination.pageNo = 1;
        this.pagination.pos = 0;
        this.fetchData();
      },
      // 请求数据
      fetchData: async function () {
        this.loading = true;
        this.keyword = this.keyword.trim();
        const params = {
          webinar_id: this.webinarId,
          interact_reward_id: this.interactRewardId,
          winner_nickname: this.keyword, // 用户昵称
          limit: this.pagination.limit,
          pos: this.pagination.pos
        };
        const [err, res] = await to(this.$fetch('getInteractRewardUsers', params));
        this.loading = false;
        if (err || !res || res.code != 200) {
          this.$message.error(err.msg || res?.msg || '请求数据失败');
          return;
        }
        this.isInited = true;
        this.total = res.data.total || 0;
        this.dataList = res.data.list;
      }
    },
    created() {
      this.webinarId = this.$route.params.str || '';
      this.interactRewardId = this.$route.query.id || '';
    },
    mounted() {
      this.fetchData();
    }
  };
</script>
